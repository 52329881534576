import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { HomePage } from "~/pages/home";
import { PublicationsPage } from "~/pages/publications";
import { RootPage } from "~/pages/root";
import { TopicPage } from "~/pages/topic";
import { TopicsPage } from "~/pages/topics";
import { VisualizationsPage } from "~/pages/visualizations";

export const routePaths = {
  root: "/",
  topics: "/topics",
  topic: "/topics/:topicId",
  visualizations: "/visualizations",
  publications: "/publications",
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={routePaths.root} element={<RootPage />}>
        <Route index element={<HomePage />} />
        <Route path={routePaths.topics} element={<TopicsPage />} />
        <Route path={routePaths.topic} element={<TopicPage />} />
        <Route
          path={routePaths.visualizations}
          element={<VisualizationsPage />}
        />
        <Route path={routePaths.publications} element={<PublicationsPage />} />
      </Route>
    </>
  )
);

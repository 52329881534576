import { IndicatorEntity, IndicatorType } from "./indicator-entity";
import {
  GetMapShapeFilesPropsT,
  IndicatorRepositoryContract,
  MapShapeFileResponse,
} from "./indicator-repository-contract";
import { createUrl } from "~/utils/functions/create-url";

class IndicatorRepository implements IndicatorRepositoryContract {
  async getMapShapeFiles({
    location = "CENSUS-TZ-REGIONS",
    ...props
  }: GetMapShapeFilesPropsT): MapShapeFileResponse {
    const key = "MAP-SHAPE-FILE-DATA";
    const _shapeFileData = localStorage.getItem(key);

    if (_shapeFileData) {
      return {
        shapeFileData: JSON.parse(_shapeFileData),
        mapData: JSON.parse(_shapeFileData)?.features,
      };
    }

    const params = props.getMapParams(location);
    const shapeFileData = await props.geoService(params);

    localStorage.setItem(key, JSON.stringify(shapeFileData));

    // @ts-ignore
    let mapData = shapeFileData?.features;

    return { shapeFileData, mapData };
  }
  async getIndicatorInfo(
    sectorKey: string,
    indicatorKey: string
  ): Promise<IndicatorEntity[]> {
    const url = createUrl(
      `dhs/datavalue/?subsectorkey=${sectorKey}&indicatorkey=${indicatorKey}`
    );

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(
          `Failed to fetch data: ${response.status} ${response.statusText}`
        );
      }

      const data = await response.json();

      return data.map((data) => new IndicatorEntity(data as IndicatorType));
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

export const indicatorRepository = new IndicatorRepository();

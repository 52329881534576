import { TopicEntity } from "./topic-entity";
import { TopicService, topicService } from "./topic-service";

class TopicUseCases {
  constructor(private topicService: TopicService) {}

  async getTopics(): Promise<TopicEntity[]> {
    return await this.topicService.getAll();
  }

  async getAllTopics(): Promise<{
    topics: {
      subsectorkey: number;
      subsector_name: string;
    }[];
    allTopics: TopicEntity[];
  }> {
    const topics = await this.getTopics();

    const uniqueTopicSectors = new Set<number>();

    const mappedTopics = topics.map(({ subsectorkey, subsector_name }) => {
      uniqueTopicSectors.add(subsectorkey);
      return { subsector_name, subsectorkey };
    });

    return {
      topics: [...uniqueTopicSectors].map(
        (sectorKey) =>
          mappedTopics.find((topic) => topic.subsectorkey === sectorKey)!
      ),
      allTopics: topics,
    };
  }

  async getAllTopicIndicators(
    subsectorkey: number,
    topics: TopicEntity[]
  ): Promise<
    | false
    | {
        indicators: { indicator_name: string; indicatorkey: string }[];
        title: string;
      }
  > {
    // const topics = await this.getTopics();

    const filteredTopics = topics.filter(
      (topic) => topic.subsectorkey === subsectorkey
    );

    const indicators = filteredTopics.map(
      ({ indicator_name, indicatorkey }) => ({
        indicator_name,
        indicatorkey: String(indicatorkey),
      })
    );

    const title = filteredTopics[0]?.subsector_name ?? "--";

    return { indicators, title };
  }

  async getAllTopicIndicatorsV2(
    subsectorkey: number,
    topics?: TopicEntity[]
  ): Promise<
    | false
    | {
        indicators: { indicator_name: string; indicatorkey: string }[];
        title: string;
      }
  > {
    if (!topics) return false;

    const filteredTopics = topics.filter(
      (topic) => topic.subsectorkey === subsectorkey
    );

    const indicators = filteredTopics.map(
      ({ indicator_name, indicatorkey }) => ({
        indicator_name,
        indicatorkey: String(indicatorkey),
      })
    );

    const title = filteredTopics[0].subsector_name ?? "--";

    return { indicators, title };
  }
}

export const topicUseCases = new TopicUseCases(topicService);

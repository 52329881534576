import { notifications } from "@mantine/notifications";
import * as XLSX from "xlsx";
import { sampleZoneInfo } from "~/domains/indicator/ui/hooks/use-topic-page";

export function downloadRegionProfileData(props: {
  name: string;
  extension: "xlsx" | "csv";
  columnHeaders: string[];
  data: { region: string; zone: string; value: number }[];
  withZone?: boolean;
}) {
  const newColumnHeaders = props.withZone
    ? props.columnHeaders
    : props.columnHeaders.filter((dt) => dt !== "Zone");
  try {
    const csvData = [
      newColumnHeaders,
      ...props.data
        .sort((a, b) => b.value - a.value)
        .map((dt) =>
          props.withZone
            ? [dt.region, getRegionZone(dt.region), dt.value.toString()]
            : [dt.region, dt.value.toString()]
        ),
    ];
    const worksheet = XLSX.utils.json_to_sheet(csvData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    /* fix row A1 */
    XLSX.utils.sheet_add_aoa(worksheet, [newColumnHeaders.map((_, __) => "")], {
      origin: "A1",
    });

    /* calculate column width */
    let min_width = 20;
    let max_width = min_width;
    csvData.forEach((csvEntry) => {
      let _max_width = (csvEntry as string[]).reduce(
        (w, r) => Math.max(w, r?.length ?? 10),
        min_width
      );
      max_width = Math.max(_max_width, max_width);
    });

    const fileExtension = `.${props.extension}`;

    worksheet["!cols"] = newColumnHeaders.map(() => ({ wch: max_width }));

    const fileName = `${props.name}${fileExtension}`;

    XLSX.writeFile(workbook, fileName);
  } catch (error) {
    notifications.show({
      message: `Error downloading ${props.extension} file`,
      color: "red",
    });
    throw error;
  }
}

function getRegionZone(region: string) {
  return (
    sampleZoneInfo.find((zoneObject) => zoneObject.regions?.includes(region))
      ?.name ?? "-"
  );
}

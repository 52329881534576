import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Group,
  Modal,
  MultiSelect,
  Stack,
  Tabs,
  Text,
} from "@mantine/core";
import {
  IconAdjustmentsHorizontal,
  IconChevronDown,
  IconChevronRight,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { IndicatorInfoExtraI } from "~/domains/indicator/indicator-usecases.ts";
import { SelectedFilterKeyT, SelectedFiltersI } from "../hooks/use-topic-page";
import { areaZonesTv2 } from "~/domains/visualization/utils/areaZonesColors";

export const TopicIndicatorInfoFilters: React.FC<{
  selectedFilters: SelectedFiltersI;
  indicatorInfoExtra: IndicatorInfoExtraI | undefined;
  onChangeFilter: (
      type: SelectedFilterKeyT,
      value: string | string[] | null | undefined
  ) => void;
  defaultTimeValue: string;
  onChangeSelectedAreas: (areaCode: string) => void;
  selectedAreas: string[];
  selectedZones: areaZonesTv2[];
  setSelectedZones: React.Dispatch<React.SetStateAction<areaZonesTv2[]>>;
  zones: areaZonesTv2[];
  isFiltersOpened: boolean;
  onOpenFilters: () => void;
  onCloseFilters: () => void;
  selectedFilterTab: string;
  onChangeSelectedFilterTab: (value: string) => void;
}> = function ({
  indicatorInfoExtra,
  onChangeFilter,
  selectedFilters,
  defaultTimeValue,
  selectedZones,
  setSelectedZones,
  zones,
  isFiltersOpened,
  onOpenFilters,
  onCloseFilters,
  selectedFilterTab,
  onChangeSelectedFilterTab,
}) {
  const [selectedAreas, setSelectedAreas] = useState<string[]>([]);
  const onChangeSelectedAreas = function (areaCode: string) {
      if (selectedAreas.includes(areaCode)) {
          setSelectedAreas([
              ...selectedAreas.filter((area) => area !== areaCode),
          ]);
          return;
      }

      setSelectedAreas([...selectedAreas, areaCode]);
  };
  const [allLevelsSelected, setAllLevelsSelected] = React.useState(false);
  const [allStatesLevelsSelected, setAllStatesLevelsSelected] =
      React.useState(false);

  const allSubgroupKeys: string[] =
      indicatorInfoExtra?.subGroups.map((sb) => sb._subgroupkey.toString()) ??
      [];

  const allCountries =
      indicatorInfoExtra?.areasV2?.countries.map((area) => area.area_code) ??
      [];
  const allStates =
      indicatorInfoExtra?.areasV2?.states.map((area) => area.area_code) ?? [];
  const allRegions =
      indicatorInfoExtra?.areasV2?.regions.map((area) => area.area_code) ??
      [];

  useEffect(() => {
      setSelectedAreas([...allCountries, ...allStates, ...allRegions]);
      setAllLevelsSelected(true);
      setAllStatesLevelsSelected(true);
      onChangeFilter("areas", [...allCountries, ...allStates, ...allRegions]);
      onChangeFilter("periods", [defaultTimeValue]);
  }, []);

  useEffect(() => {
      // 74 is the sub-group key for total
      const totalSubgroup = allSubgroupKeys.filter((group) => group === "74");
      const initialSubgroups = totalSubgroup.length
          ? totalSubgroup
          : allSubgroupKeys.filter((_, index) => index === 0);

      if (!indicatorInfoExtra?.subGroups) return;

      if (initialSubgroups.length === 0) {
          onChangeSelectedFilterTab("subgroups");
          onOpenFilters();
      }

      if (initialSubgroups.length === 1) {
          onChangeFilter("subGroups", initialSubgroups);
          onCloseFilters();
      }
  }, [indicatorInfoExtra?.subGroups]);

  const onSelectEverything = React.useCallback(() => {
      if (allLevelsSelected) {
          let areas: string[] = [];
          setAllLevelsSelected(false);
          setAllStatesLevelsSelected(false);
          onChangeFilter("areas", areas);
      }

      if (!allLevelsSelected) {
          let areas: string[] = [
              ...allCountries,
              ...allStates,
              ...allRegions,
          ];
          setAllLevelsSelected(true);
          setAllStatesLevelsSelected(true);
          onChangeFilter("areas", areas);
      }
  }, [allLevelsSelected]);

  const onChangeCountry = React.useCallback(
      (props: { isSelected: boolean; countryCode: string }) => {
          if (props.isSelected) {
              let areas: string[] = (selectedFilters.areas ?? []).filter(
                  (area) => area !== props.countryCode
              );
              setAllLevelsSelected(false);
              setAllStatesLevelsSelected(false);
              onChangeFilter("areas", areas);
          }

          if (!props.isSelected) {
              let areas: string[] = [
                  ...(selectedFilters.areas ?? []),
                  props.countryCode,
              ];
              setAllLevelsSelected(false);
              onChangeFilter("areas", areas);
          }
      },
      [selectedFilters.areas]
  );

  const onChangeState = React.useCallback(
      (props: { isStateSelected: boolean; stateCode: string }) => {
          if (props.isStateSelected) {
              let areas: string[] = (selectedFilters.areas ?? []).filter(
                  (rg) => rg !== props.stateCode
              );
              setAllLevelsSelected(false);
              setAllStatesLevelsSelected(false);
              onChangeFilter("areas", areas);
          }
          if (!props.isStateSelected) {
              let areas: string[] = [
                  ...(selectedFilters.areas ?? []),
                  props.stateCode,
              ];
              setAllLevelsSelected(false);
              onChangeFilter("areas", areas);
          }
      },
      [selectedFilters.areas]
  );

  const onSelectEverythingStates = React.useCallback(
      (props: { countryCode: string }) => {
          if (allStatesLevelsSelected) {
              let areas: string[] = (selectedFilters.areas ?? []).filter(
                  (area) => area === props.countryCode
              );
              setAllLevelsSelected(false);
              setAllStatesLevelsSelected(false);
              onChangeFilter("areas", areas);
          }

          if (!allStatesLevelsSelected) {
              let areas: string[] = [
                  ...(selectedFilters.areas ?? []).filter(
                      (area) => area === props.countryCode
                  ),
                  ...allStates,
                  ...allRegions,
              ];
              setAllStatesLevelsSelected(true);
              onChangeFilter("areas", areas);
          }
      },
      [selectedFilters.areas, allStatesLevelsSelected]
  );

  const subgroups = React.useMemo(
      () => indicatorInfoExtra?.subGroups,
      [indicatorInfoExtra?.subGroups]
  );

  // const [isFiltersOpened, {open: onOpenFilters, close: onCloseFilters}] = useDisclosure (false);
  const minimumTabItemHeight = 400;

  return (
      <Stack spacing={0}>
          <Tabs
              value={selectedFilterTab}
              onTabChange={(value) => {
                  onChangeSelectedFilterTab(value);
              }}
          >
              <Modal.Root
                  opened={isFiltersOpened}
                  onClose={onCloseFilters}
                  centered
                  size={"xl"}
              >
                  <Modal.Overlay />
                  <Modal.Content>
                      <Modal.Header className="flex items-start">
                          <Modal.Title>
                              <Stack
                                  w={"100%"}
                                  spacing={"xs"}
                                  align="flex-start"
                              >
                                  <Text>Indicator Filters</Text>
                                  <Tabs.List w={"100%"}>
                                      <Tabs.Tab value="selected-areas">
                                          Selected Areas
                                      </Tabs.Tab>
                                      <Tabs.Tab value="zones">Zones</Tabs.Tab>
                                      <Tabs.Tab value="subgroups">
                                          Sub Groups
                                      </Tabs.Tab>
                                      <Tabs.Tab value="periods">
                                          Time Periods
                                      </Tabs.Tab>
                                  </Tabs.List>
                              </Stack>
                          </Modal.Title>
                          <Modal.CloseButton />
                      </Modal.Header>
                      <Modal.Body>
                          <Tabs.Panel value="selected-areas">
                              <Stack spacing={"sm"}>
                                  <Checkbox
                                      checked={allLevelsSelected}
                                      value={"everything"}
                                      color="brand"
                                      onChange={onSelectEverything}
                                      label={
                                          allLevelsSelected
                                              ? "Remove All"
                                              : "Select All"
                                      }
                                  />

                                  {indicatorInfoExtra?.areasV2?.formattedData?.map(
                                      (country) => {
                                          let isCountrySelected =
                                              selectedFilters.areas?.includes(
                                                  country.area_code
                                              );

                                          return (
                                              <Stack key={country.area_code}>
                                                  <Group position="left">
                                                      <Checkbox
                                                          checked={
                                                              isCountrySelected
                                                          }
                                                          value={
                                                              country.area_code
                                                          }
                                                          onChange={() =>
                                                              onChangeCountry(
                                                                  {
                                                                      countryCode:
                                                                          country.area_code,
                                                                      isSelected:
                                                                          Boolean(
                                                                              isCountrySelected
                                                                          ),
                                                                  }
                                                              )
                                                          }
                                                          label={
                                                              country.area_name
                                                          }
                                                      />
                                                  </Group>

                                                  <Stack
                                                      spacing={"sm"}
                                                      ml={"lg"}
                                                  >
                                                      {(function () {
                                                          const mappedRegions2: string[] =
                                                              [];
                                                          country.states.forEach(
                                                              (stateObj) => {
                                                                  stateObj.regions.forEach(
                                                                      (
                                                                          regionObj
                                                                      ) => {
                                                                          mappedRegions2.push(
                                                                              regionObj.area_code
                                                                          );
                                                                      }
                                                                  );
                                                              }
                                                          );

                                                          return (
                                                              <Group position="left">
                                                                  <Box
                                                                      sx={{
                                                                          opacity: 0,
                                                                      }}
                                                                  >
                                                                      <TreeIcon
                                                                          open
                                                                          onClick={() => {}}
                                                                      />
                                                                  </Box>

                                                                  <Checkbox
                                                                      checked={
                                                                          allStatesLevelsSelected
                                                                      }
                                                                      value={
                                                                          "all-states"
                                                                      }
                                                                      color="brand"
                                                                      onChange={() =>
                                                                          onSelectEverythingStates(
                                                                              {
                                                                                  countryCode:
                                                                                      country.area_code,
                                                                              }
                                                                          )
                                                                      }
                                                                      label={
                                                                          allStatesLevelsSelected
                                                                              ? "Remove All"
                                                                              : "Select All"
                                                                      }
                                                                  />
                                                              </Group>
                                                          );
                                                      })()}
                                                      <Stack spacing={"sm"}>
                                                          {country?.states?.map(
                                                              (state) => {
                                                                  let isStateSelected =
                                                                      selectedFilters.areas?.includes(
                                                                          state.area_code
                                                                      );
                                                                  let isStateOpen =
                                                                      selectedAreas.includes(
                                                                          state.area_code
                                                                      );

                                                                  return (
                                                                      <>
                                                                          <Group position="left">
                                                                              <TreeIcon
                                                                                  open={
                                                                                      isStateOpen
                                                                                  }
                                                                                  onClick={() =>
                                                                                      onChangeSelectedAreas(
                                                                                          state.area_code
                                                                                      )
                                                                                  }
                                                                              />

                                                                              <Checkbox
                                                                                  checked={
                                                                                      isStateSelected
                                                                                  }
                                                                                  value={
                                                                                      state.area_code
                                                                                  }
                                                                                  onChange={() =>
                                                                                      onChangeState(
                                                                                          {
                                                                                              isStateSelected:
                                                                                                  Boolean(
                                                                                                      isStateSelected
                                                                                                  ),
                                                                                              stateCode:
                                                                                                  state.area_code,
                                                                                          }
                                                                                      )
                                                                                  }
                                                                                  label={
                                                                                      state.area_name
                                                                                  }
                                                                              />
                                                                          </Group>

                                                                          <Stack
                                                                              spacing={
                                                                                  "xs"
                                                                              }
                                                                              ml={
                                                                                  "lg"
                                                                              }
                                                                              sx={{
                                                                                  display:
                                                                                      isStateOpen
                                                                                          ? undefined
                                                                                          : "none",
                                                                              }}
                                                                          >
                                                                              <Stack
                                                                                  ml={
                                                                                      45
                                                                                  }
                                                                                  spacing={
                                                                                      "md"
                                                                                  }
                                                                              >
                                                                                  {(function () {
                                                                                      const mappedRegions =
                                                                                          state.regions.map(
                                                                                              (
                                                                                                  reg
                                                                                              ) =>
                                                                                                  reg.area_code
                                                                                          );
                                                                                      const allRegionsSelected =
                                                                                          mappedRegions?.every(
                                                                                              (
                                                                                                  area
                                                                                              ) =>
                                                                                                  selectedFilters.areas?.includes(
                                                                                                      area
                                                                                                  )
                                                                                          );

                                                                                      return (
                                                                                          <Group>
                                                                                              <Checkbox
                                                                                                  checked={
                                                                                                      allRegionsSelected
                                                                                                  }
                                                                                                  value={
                                                                                                      "all-regions"
                                                                                                  }
                                                                                                  color="brand"
                                                                                                  onChange={(
                                                                                                      _
                                                                                                  ) => {
                                                                                                      setAllLevelsSelected(
                                                                                                          false
                                                                                                      );

                                                                                                      onChangeFilter(
                                                                                                          "areas",
                                                                                                          allRegionsSelected
                                                                                                              ? selectedFilters.areas?.filter(
                                                                                                                    (
                                                                                                                        area
                                                                                                                    ) =>
                                                                                                                        !mappedRegions.includes(
                                                                                                                            area
                                                                                                                        )
                                                                                                                )
                                                                                                              : [
                                                                                                                    ...(selectedFilters.areas ??
                                                                                                                        []),
                                                                                                                    ...mappedRegions,
                                                                                                                ]
                                                                                                      );
                                                                                                  }}
                                                                                                  label={
                                                                                                      allRegionsSelected
                                                                                                          ? "Remove All"
                                                                                                          : "Select All"
                                                                                                  }
                                                                                              />
                                                                                          </Group>
                                                                                      );
                                                                                  })()}

                                                                                  <Stack
                                                                                      spacing={
                                                                                          "sm"
                                                                                      }
                                                                                  >
                                                                                      {state?.regions.map(
                                                                                          (
                                                                                              region
                                                                                          ) => {
                                                                                              let isRegionSelected =
                                                                                                  selectedFilters.areas?.includes(
                                                                                                      region.area_code
                                                                                                  );
                                                                                              return (
                                                                                                  <>
                                                                                                      <Group position="left">
                                                                                                          <Checkbox
                                                                                                              checked={
                                                                                                                  isRegionSelected
                                                                                                              }
                                                                                                              color="brand"
                                                                                                              onChange={(
                                                                                                                  _
                                                                                                              ) =>
                                                                                                                  onChangeFilter(
                                                                                                                      "areas",
                                                                                                                      isRegionSelected
                                                                                                                          ? selectedFilters.areas?.filter(
                                                                                                                                (
                                                                                                                                    area
                                                                                                                                ) =>
                                                                                                                                    area !==
                                                                                                                                    region.area_code
                                                                                                                            )
                                                                                                                          : [
                                                                                                                                ...(selectedFilters.areas ??
                                                                                                                                    []),
                                                                                                                                region.area_code,
                                                                                                                            ]
                                                                                                                  )
                                                                                                              }
                                                                                                              label={
                                                                                                                  region.area_name
                                                                                                              }
                                                                                                          />
                                                                                                      </Group>
                                                                                                  </>
                                                                                              );
                                                                                          }
                                                                                      )}
                                                                                  </Stack>
                                                                              </Stack>
                                                                          </Stack>
                                                                      </>
                                                                  );
                                                              }
                                                          )}
                                                      </Stack>
                                                  </Stack>
                                              </Stack>
                                          );
                                      }
                                  )}
                              </Stack>
                          </Tabs.Panel>

                          <Tabs.Panel
                              value="zones"
                              mih={minimumTabItemHeight}
                          >
                              <MultiSelect
                                  value={selectedZones}
                                  onChange={(values) =>
                                      setSelectedZones(
                                          values as areaZonesTv2[]
                                      )
                                  }
                                  data={zones}
                                  label="Filter areas by zone"
                                  placeholder="Select zone"
                                  searchable
                                  nothingFound="Nothing found"
                              />
                          </Tabs.Panel>

                          <Tabs.Panel
                              value="subgroups"
                              mih={minimumTabItemHeight}
                          >
                              <MultiSelect
                                  label="Subgroups"
                                  placeholder="Select Subgroups"
                                  searchable
                                  nothingFound="No Subgroups found"
                                  miw={350}
                                  w={"100%"}
                                  value={selectedFilters.subGroups}
                                  data={
                                      subgroups
                                          ?.map((subGroup) => ({
                                              label: subGroup._subgroup_desc,
                                              value: subGroup._subgroupkey.toString(),
                                          }))
                                          .sort((a, b) =>
                                              a.label.toLowerCase() >
                                              b.label.toLowerCase()
                                                  ? 1
                                                  : -1
                                          ) ?? [""]
                                  }
                                  onChange={(value) =>
                                      onChangeFilter("subGroups", value)
                                  }
                              />
                          </Tabs.Panel>

                          <Tabs.Panel
                              value="periods"
                              mih={minimumTabItemHeight}
                          >
                              <MultiSelect
                                  label="Periods"
                                  placeholder="Select Periods"
                                  searchable
                                  nothingFound="No Periods found"
                                  miw={350}
                                  w={"100%"}
                                  value={
                                      selectedFilters.periods ?? [
                                          defaultTimeValue,
                                      ]
                                  }
                                  data={
                                      indicatorInfoExtra?.timeValues.sort(
                                          (a, b) =>
                                              a.toLowerCase() >
                                              b.toLowerCase()
                                                  ? 1
                                                  : -1
                                      ) ?? []
                                  }
                                  onChange={(value) =>
                                      onChangeFilter("periods", value)
                                  }
                              />

                              <Text size={"xs"} color="dimmed">
                                  {selectedFilters.periods?.length
                                      ? ""
                                      : `Defaults to ${defaultTimeValue}`}
                              </Text>
                          </Tabs.Panel>
                      </Modal.Body>
                  </Modal.Content>
              </Modal.Root>
          </Tabs>

          <Button
              rightIcon={<IconAdjustmentsHorizontal />}
              variant={"outline"}
              onClick={onOpenFilters}
          >
              Open Filters
          </Button>
      </Stack>
  );
};

const TreeIcon: React.FC<{ open: boolean; onClick(): void }> = function ({
  open,
  onClick,
}) {
  return (
      <ActionIcon variant="light" onClick={onClick}>
          {open ? (
              <IconChevronDown size={20} />
          ) : (
              <IconChevronRight size={20} />
          )}
      </ActionIcon>
  );
};

import { Box, Loader } from "@mantine/core";
import React, { useRef } from "react";

import { MapShapeFileResponse } from "../../indicator-repository-contract";

import HighchartsReact from "highcharts-react-official";

import Highcharts from "highcharts/highmaps";
import highChartsMore from "highcharts/highcharts-more";
import accessibilityModule from "highcharts/modules/accessibility";
import dataModule from "highcharts/modules/data";
import drillDown from "highcharts/modules/drilldown";
import exportModule from "highcharts/modules/exporting";
import mapModule from "highcharts/modules/map";
import boostModule from "highcharts/modules/boost";
import { IndicatorInfoExtraI } from "../../indicator-usecases";
import { SelectedFiltersI } from "../hooks/use-topic-page";

accessibilityModule(Highcharts);
mapModule(Highcharts);
drillDown(Highcharts);
dataModule(Highcharts);
exportModule(Highcharts);
highChartsMore(Highcharts);
boostModule(Highcharts);

export const TopicIndicatorMapView: React.FC<{
  selectedFilters: SelectedFiltersI;
  indicatorInfoExtra: IndicatorInfoExtraI | undefined;
  show: boolean;
  dataV2: {
    columns: { column1: string[]; column2: string[] };
    data: string[][];
  };
  title?: string;
  mapData: {
    loadingMapInfo: boolean;
    mapShapeFiles: Awaited<MapShapeFileResponse> | undefined;
  };
}> = function ({ show, title, mapData, dataV2 }) {
  const _seriesEntry = {
    name: "base",
    nullColor: "#87cefa",
    allAreas: true,
    color: "#fff",
  };

  const allLocations = dataV2.data.map((dt) => dt[0]);

  const dataArray = allLocations.map((_location) => {
    let value = dataV2.data
      .find((dt) => dt[0] === _location)
      ?.find((_, ind) => ind === 1);

    return { location: _location, value };
  });

  const seriesEntry = seriesDataGenerator(dataArray) as any;

  if (!show) return undefined;

  if (mapData.loadingMapInfo) return <Loader size={"sm"} />;

  return (
    <Box>
      <Chart
        Highcharts={Highcharts}
        options={
          {
            series: [_seriesEntry as any, seriesEntry],
          } as Highcharts.Options
        }
        title={`${title}`}
        dataV2={dataV2}
      />
    </Box>
  );

  function seriesDataGenerator(
    dataArray: {
      location: string;
      value: string | number | null | undefined;
    }[]
  ) {
    const seriesData = (function (shapeFileData) {
      // @ts-ignore
      let mapData = shapeFileData?.features;
      let arr: {
        Region_Nam?: string;
        LAKES?: string;
        value?: number | null;
      }[] = [];

      if (mapData) {
        let _x: any = [];
        mapData?.forEach((el: any) => {
          el.drilldown = el.properties?.Region_Cod;
          el.region_nam =
            el.properties?.Region_Nam ?? el.properties?.LAKES ?? "";
          const targetData = dataArray
            .map((dt) =>
              dt.value === 0
                ? { ...dt, value: undefined }
                : { ...dt, value: dt.value }
            )
            .find(({ location }) => location === el.region_nam);

          if (!el.properties?.Region_Nam) {
            el["color"] = "#87cefa";
          } else {
            if (!targetData?.location) {
              let obj = { LAKES: el.properties?.LAKES ?? "" };
              arr.push(obj);
              el.value = undefined;
            } else {
              let obj = {
                Region_Nam: targetData.location,
                value: targetData?.value ? Number(targetData?.value) : null,
              };
              arr.push(obj);
              el.value = targetData?.value ? Number(targetData?.value) : null;
            }
          }

          _x.push({
            ...el,
            drilldown: el.properties?.Region_Cod,
            region_nam: el.properties?.Region_Nam,
            value: el.value,
            color: el["color"],
          });

          el["color"] = undefined;
        });
        return _x;
      }

      return undefined;
    })(mapData.mapShapeFiles?.shapeFileData);

    return {
      mapData: mapData.mapShapeFiles?.shapeFileData,
      data: seriesData,
      nullColor: "#ffffff",
      type: "map",
      name: "Region",
      allAreas: false,
      color: "#eb1818",
      borderWidth: 1,
      dataLabels: {
        enabled: true,

        formatter: function (): string {
          //@ts-ignore
          let regionName = this?.point?.properties?.Region_Nam ?? "";
          //@ts-ignore
          let lakeName = this?.point?.properties?.LAKES ?? "";
          //@ts-ignore
          let pointValue = this.point.value ?? "";

          //@ts-ignore
          if (typeof pointValue === typeof 0) {
            return regionName
              ? `${regionName}<br />${Intl.NumberFormat().format(
                  pointValue ?? ""
                )}`
              : lakeName;
          }

          if (lakeName) return lakeName;

          return regionName;
        },

        style: {
          color: "#fff",
          textShadow: "1px 1px 0 #000",
          fontSize: "14px",
        },
      },
      tooltip: {
        // @ts-ignore
        shared: true,
        useHTML: true,
        headerFormat: "<ul>",
        pointFormatter: function () {
          //@ts-ignore
          let value = this.value === undefined ? "" : this.value;
          let txt = ``;
          //@ts-ignore
          txt +=
            "<h1><b>" +
            this.properties?.Region_Nam?.toUpperCase() +
            "</b></h1></br>";
          txt += `<h1><b> ${
            value ? value.toLocaleString() : ""
          }</b>  </h1> </br>`;
          return txt;
        },
        footerFormat: "</ul>",
      },
    };
  }
};

const Chart: React.FC<{
  Highcharts: any;
  options: Highcharts.Options;
  title: string;
  dataV2: {
    columns: { column1: string[]; column2: string[] };
    data: string[][];
  };
}> = function ({ Highcharts, options, title, dataV2 }) {
  const topAreas = ["Tanzania", "Mainland", "Zanzibar"];

  const topLevelData = dataV2.data.filter((dt) => topAreas.includes(dt[0]));

  const getTopLevelData = function (keyText: string) {
    return dataV2.columns.column1
      .filter((column) => column.length)
      .map((_, columnIndex) => {
        const foundItem = topLevelData.find(
          (dt) => (dt[0] ?? "").toString() === keyText
        );
        const result = foundItem?.[columnIndex + 1] ?? null;

        return result;
      });
  };

  // @ts-ignore
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const _options: Highcharts.Options = {
    title: { text: title },
    subtitle: {
      text: `Tanzania (${getTopLevelData("Tanzania") ?? null}), Mainland (${
        getTopLevelData("Mainland") ?? null
      }), Zanzibar (${getTopLevelData("Zanzibar") ?? null})`,
      style: {
        fontSize: "1rem",
        fontWeight: "bold",
        color: "black",
      },
    },
    exporting: { enabled: false },
    yAxis: { min: 0 },
    chart: {
      type: "map",
      height: `100%`,
      animation: {
        defer: 0,
        duration: 300,
      },
      // @ts-ignore
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: "bottom",
        },
      },
    },
    credits: {
      enabled: true,
      position: {
        align: "center",
        x: 20,
      },
      text: "NBS Tanzania",
      href: "https://www.nbs.go.tz/index.php/en/census-surveys/gis/568-tanzania-districts-shapefiles-2019",
    },
    legend: {
      layout: "vertical",
      align: "left",
      verticalAlign: "middle",
    },

    colorAxis: {
      min: 0,
      minColor: "#F2FFE9",
      maxColor: "#1E6F5C",
    },

    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "top",
        align: "right",
      },
      // @ts-ignore
      x: 10,
    },

    plotOptions: {
      map: {
        states: {
          hover: {
            color: "#EEDD66",
          },
        },
        // @ts-ignore
        animation: { defer: 0, duration: 200 },

        borderColor: "#dddddd", // Set a border color to differentiate lakes
        borderWidth: 1,
      },
    },

    series: [
      // @ts-ignore
      {
        name: "base",
        nullColor: "#87cefa",
        allAreas: true,
        color: "#fff",
      },
    ],
    drilldown: {
      activeDataLabelStyle: {
        color: "#FFFFFF",
        textDecoration: "none",
        textOutline: "1px #000000",
      },
      // @ts-ignore
      drillUpButton: {
        relativeTo: "spacingBox",
        position: {
          x: 0,
          y: 60,
        },
      },
    },
  };

  return (
    <HighchartsReact
      constructorType={"mapChart"}
      highcharts={Highcharts}
      options={{ ..._options, ...options }}
      ref={chartComponentRef}
      // @ts-ignore
      {...HighchartsReact.Props}
    />
  );
};

export default TopicIndicatorMapView;

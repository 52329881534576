import { NavLink } from "react-router-dom";
import { routePaths } from "~/router/router";
import { Group, clsx, createStyles } from "@mantine/core";
import { useTranslator } from "~/i18n/translator-hook";
import React from "react";

const style = createStyles((theme) => ({
  active: {
    textDecoration: "none",
    backgroundColor: "white",
    // color: theme.colors.gray[8],
    color: "black",
  },

  normal: {
    // color: "black",
    color: "white",
    // fontWeight: "bold",
    textDecoration: "none",
    paddingInline: theme.spacing.lg,
    paddingBlock: 5,
    // border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    fontSize: theme.fontSizes.sm,
    textTransform: "uppercase",
  },
}));

export const NavbarCmp: React.FC = () => {
  const { classes } = style();
  const translator = useTranslator();
  const links = [
    { label: translator("navbar_home"), path: routePaths.root },
    { label: translator("navbar_topics"), path: routePaths.topics },
    {
      label: translator("navbar_visualizations"),
      path: routePaths.visualizations,
    },
    { label: translator("navbar_publications"), path: routePaths.publications },
  ];

  return (
    <nav>
      <Group spacing={"md"}>
        {links.map(({ label, path }, linkIndex) => (
          <NavLink
            key={linkIndex}
            to={path}
            style={({ isActive }) => ({
              color: isActive ? "black" : "white",
              fontWeight: isActive ? 600 : undefined,
            })}
            className={({ isActive }) =>
              clsx(classes.normal, isActive && classes.active)
            }
          >
            {label}
          </NavLink>
        ))}
      </Group>
    </nav>
  );
};

import { Loader, Stack, Tabs, Text } from "@mantine/core";
import { useGetVisualizations } from "~/domains/visualization/ui/hooks/use-get-visualizations";

import * as Highcharts from "highcharts";
import highChartsMore from "highcharts/highcharts-more";
import accessibilityModule from "highcharts/modules/accessibility";
import boostModule from "highcharts/modules/boost";
import dataModule from "highcharts/modules/data";
import drillDown from "highcharts/modules/drilldown";
import {
  default as exportModule,
  default as exporting,
} from "highcharts/modules/exporting";
import mapModule from "highcharts/modules/map";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BreadcrumbCreator } from "~/components/breadcrumb-creator";
import { topicVisualizationDataTv2 } from "~/domains/visualization/utils/topicVisualizationDataType";
import { routePaths } from "~/router/router";
import { Visualizer } from "~/domains/visualization/ui/components/Visualizer.tsx";

exporting(Highcharts);
accessibilityModule(Highcharts);
mapModule(Highcharts);
drillDown(Highcharts);
dataModule(Highcharts);
exportModule(Highcharts);
highChartsMore(Highcharts);
boostModule(Highcharts);

export const VisualizationsPage: React.FC = function () {
  const hook = useGetVisualizations();
  const breadCrumbs = [
    { title: "DHS", path: routePaths.root },
    { title: "Visualizations" },
  ];
  const [activeTab, setActiveTab] = useState<string | null>("nutrition");

  useEffect(() => {
    if (hook.topics?.length) {
      setActiveTab(hook.topics[0].subsectorkey.toString());
    }
  }, [hook.topics]);

  const tabsList = useMemo(() => {
    const sortedTopics = hook.topics?.sort((a, b) => {
      const aSubsector = a.subsector_name.toLowerCase();
      const bSubsector = b.subsector_name.toLowerCase();

      const aStartsWithNutrition = aSubsector.startsWith("nutrition");
      const bStartsWithNutrition = bSubsector.startsWith("nutrition");
      const aStartsWithViolence = aSubsector.startsWith("violence");
      const bStartsWithViolence = bSubsector.startsWith("violence");

      // Prioritize "nutrition"
      if (aStartsWithNutrition && !bStartsWithNutrition) return -1;
      if (!aStartsWithNutrition && bStartsWithNutrition) return 1;

      // Deprioritize "violence"
      if (aStartsWithViolence && !bStartsWithViolence) return 1;
      if (!aStartsWithViolence && bStartsWithViolence) return -1;

      return 0; // Maintain original order for other cases
    });

    return sortedTopics?.map((dt) => ({
      key: dt.subsectorkey,
      label: dt.subsector_name,
      value: dt.subsectorkey.toString(),
    }));
  }, [hook.topics]);

  // Sort tabsList by key

  const tabsListMapper = useCallback(
    (item: { label: string; value: string; key: number }) => {
      return (
        <Tabs.Tab key={item.key} value={item.value}>
          <p className="font-bold">{item.label}</p>
        </Tabs.Tab>
      );
    },
    []
  );

  const tabsListPanelMapper = useCallback(
    (item: {
      label: string;
      value: string;
      key: number;
      source?: topicVisualizationDataTv2;
    }) => {
      return (
        <Tabs.Panel key={item.key} value={item.value}>
          <Visualizer source={[]} topicKey={item.value} topics={hook.topics} />
        </Tabs.Panel>
      );
    },
    [hook.topics]
  );

  if (hook.loadingMapInfo || hook.topicsLoading) return <Loader size={"sm"} />;

  return (
    <Stack spacing={"0"}>
      <BreadcrumbCreator data={breadCrumbs} />

      <Text size="sm"></Text>
      <Tabs
        variant="pills"
        radius="md"
        color={"blue"}
        value={activeTab}
        onTabChange={setActiveTab}
        keepMounted={false}
        bg={"whitesmoke"}
      >
        <Tabs.List mt={"sm"}>{tabsList.map(tabsListMapper)}</Tabs.List>

        {tabsList.map(tabsListPanelMapper)}
      </Tabs>
    </Stack>
  );
};

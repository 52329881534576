import {
  AspectRatio,
  BackgroundImage,
  Box,
  Center,
  Group,
  Overlay,
  SimpleGrid,
  Stack,
  Text,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { routePaths } from "~/router/router";
import { useTopicsImageBank } from "../hooks";
import React from "react";
import mortalityIcon from "~/assets/icons/mortality-icon.png";
import immunizationIcon from "~/assets/icons/immunization-icon.png";
import reproductiveHealthIcon from "~/assets/icons/reproductive-health-icon.png";
import violenceIcon from "~/assets/icons/violence-icon.png";
import healthInsuranceIcon from "~/assets/icons/health0insurance.png";
import birthRegistrationIcon from "~/assets/icons/birth-reg-icon.png";
import fertilityIcon from "~/assets/icons/fertility-icon.png";
import hivIcon from "~/assets/icons/hiv1.png";
import lisheIcon from "~/assets/icons/lishe.png";
import insuranceIcon from "~/assets/icons/insurance.png";
import morbidityIcon from "~/assets/icons/morbidity.png";

const iconBank = {
  Mortality: mortalityIcon,
  Immunization: immunizationIcon,
  "Reproductive Health": reproductiveHealthIcon,
  Violence: violenceIcon,
  "Health Insurance": healthInsuranceIcon,
  "Birth Registration": birthRegistrationIcon,
  Fertility: fertilityIcon,
  Nutrition: lisheIcon,
  "HIV/AIDS": hivIcon,
  Insurance: insuranceIcon,
  Morbidity: morbidityIcon,
};

export const TopicsGridUi: React.FC<{
  topics: {
    subsectorkey: number;
    subsector_name: string;
  }[];
  showTitle?: boolean;
  filtered?: boolean;
}> = function ({ topics, showTitle, filtered }) {
  const navigate = useNavigate();
  const { getTopicImage } = useTopicsImageBank();

  return (
    <Stack
      className={"bg-[#fefefe]"}
      spacing={"xl"}
      align={"center"}
      w={"100%"}
      py={50}
      justify={"center"}
      sx={(theme) => ({ border: `1px solid ${theme.colors.gray[2]}` })}
    >
      {showTitle && (
        <Stack>
          <Text align="center" weight={"bold"}>
            Click on the topic that you want to view.{" "}
            <span className={"text-gray-500 font-semibold text-sm"}>
              {filtered ? "(*Filtered)" : ""}
            </span>
          </Text>
        </Stack>
      )}

      <SimpleGrid
        spacing={"xl"}
        cols={5}
        maw={1200}
        w={"100%"}
        h={"fit-content"}
      >
        {topics.map(topicMapper)}
      </SimpleGrid>
    </Stack>
  );

  function topicMapper(topic: {
    subsectorkey: number;
    subsector_name: string;
  }) {
    const image = getTopicImage(topic.subsector_name);

    return (
      <>
        <Stack
          align={"center"}
          justify={"center"}
          onClick={() => gotoTopic(topic.subsectorkey)}
          className={
            "cursor-pointer bg-gray-100 group aspect-square transition-all hover:bg-gray-50 hover:border hover:border-gray-200 rounded-md"
          }
        >
          <Box
            sx={() => ({
              // backgroundColor: '#EBF5FF',
              // border: `2px solid ${'white'}`,
              height: 100,
              width: 100,
              // borderRadius: '100%',
              // boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
            })}
            className={"group-hover:bg-gray-10 transition-all0"}
          >
            <Center h={"100%"}>
              {iconBank[topic.subsector_name] ? (
                <img
                  src={iconBank[topic.subsector_name] ?? ""}
                  className={
                    "h-10 w-10 group-hover:h-14 group-hover:w-14 transition-all"
                  }
                  alt={""}
                />
              ) : (
                <div className={"h-10 w-10"} />
              )}
            </Center>
          </Box>

          <Text
            align="center"
            className={
              "text-gray-600 font-bold group-hover:text-black group-hover:translate-y-2 transition-all"
            }
          >
            {topic.subsector_name}
          </Text>
        </Stack>

        <AspectRatio
          key={topic.subsectorkey}
          ratio={16 / 7}
          onClick={() => gotoTopic(topic.subsectorkey)}
          sx={(theme) => ({
            display: "none",
            width: "100%",
            flexGrow: 1,
            transitionDelay: "50ms",
            transitionProperty: "all",
            border: `3px solid ${theme.colors.gray[3]}`,
            cursor: "pointer",
            color: "dimgrey",
            transition: "all .1s ease",
            // borderRadius: theme.radius.md,
            ":hover": {
              borderColor: theme.colors.gray[7],
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;",
              color: "black",
            },
          })}
        >
          <BackgroundImage
            src={image}
            sx={() => ({ width: "100%", flexGrow: 1 })}
          ></BackgroundImage>

          <Overlay
            gradient={
              image
                ? "linear-gradient(82deg, rgba(2,0,36,0) 0%, rgba(29,59,109,0.2) 10%, rgba(29,59,109, 0.6) 60%)"
                : undefined
            }
            bg={image ? undefined : "brand"}
            opacity={0.2}
          >
            <Group position="right" w={"100%"} p={"lg"}>
              <Text weight={"bold"} color="white" align="right">
                {topic.subsector_name}
              </Text>
            </Group>
          </Overlay>
        </AspectRatio>
      </>
    );
  }

  function gotoTopic(id: string | number) {
    navigate(`${routePaths.topics}/${id}`);
  }
};

import {
  ActionIcon,
  Badge,
  Group,
  Loader,
  Menu,
  ScrollArea,
  SegmentedControl,
  Select,
  SimpleGrid,
  Stack,
  Table,
  Text,
  TextInput,
  Tooltip,
  Anchor,
} from "@mantine/core";
import {
  IconFile,
  IconFileCv,
  IconSearch,
  IconPhoto,
} from "@tabler/icons-react";
import { useGetVisualizationsV2 } from "~/domains/visualization/ui/hooks/use-get-visualizations-v2.ts";
import {
  gendersT,
  tanzaniaStatesT,
  topicVisualizationDataTv2,
} from "~/domains/visualization/utils/topicVisualizationDataType.ts";

import * as Highcharts from "highcharts";
import formap from "highcharts/highmaps";
import HighchartsReact from "highcharts-react-official";
import highChartsMore from "highcharts/highcharts-more";
import accessibilityModule from "highcharts/modules/accessibility";
import boostModule from "highcharts/modules/boost";
import dataModule from "highcharts/modules/data";
import drillDown from "highcharts/modules/drilldown";
import exportModule from "highcharts/modules/exporting";
import mapModule from "highcharts/modules/map";
import React, { useEffect, useState } from "react";
import { sampleZoneInfo } from "~/domains/indicator/ui/hooks/use-topic-page";
import { TopicEntity } from "~/domains/topic/topic-entity";
import {
  areaZoneColorMapV2,
  areaZonesTv2,
} from "~/domains/visualization/utils/areaZonesColors.ts";
import { downloadLineTrendData } from "~/utils/functions/download-line-trend-data.ts";
import { downloadRegionProfileData } from "~/utils/functions/download-region-profile-data.ts";
import { downloadZoneProfileData } from "~/utils/functions/download-zone-profile-data.ts";
import { TEXTS } from "~/utils/texts.ts";
import { IconMenu2 } from "@tabler/icons-react";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";

accessibilityModule(Highcharts);
mapModule(Highcharts);
drillDown(Highcharts);
dataModule(Highcharts);
exportModule(Highcharts);
highChartsMore(Highcharts);
boostModule(Highcharts);

export function Visualizer(props: {
  source: topicVisualizationDataTv2;
  topicKey: string;
  topics: TopicEntity[];
}) {
  const hookV2 = useGetVisualizationsV2(props);
  if (hookV2.loadingIndicators) return <Loader size={"sm"} />;

  return (
    <Stack spacing={"lg"}>
      {(hookV2.selectedIndicatorData
        ? [hookV2.selectedIndicatorData]
        : []
      )?.map((optionItem) => {
        // Simon changes - Filter out categoies with null values
        optionItem.indicatorOptions.lineChartOptions.series.forEach(function (
          serie
        ) {
          serie.data = serie.data.filter(function (value) {
            return value !== null;
          });

          // Remove corresponding category from xAxis if corresponding data is null
          optionItem.indicatorOptions.lineChartOptions.xAxis.categories =
            optionItem.indicatorOptions.lineChartOptions.xAxis.categories.filter(
              function (idx) {
                return serie.data[idx] !== null;
              }
            );
        });

        const stateSelection = hookV2.selectedCategories?.find(
          (dt) => dt.key === optionItem.key
        )?.stateSelection;
        const sortedZoneBarDataValues =
          optionItem.indicatorOptions.bubbleChartOptions?.["series"]
            ?.map((dt) => ({
              ...dt,
              data: undefined,
              y: dt?.data[0]?.value ?? null,
            }))
            .sort((a, b) => (a.y > b.y ? 1 : -1));
        const sortedZoneBarCategories = sortedZoneBarDataValues.map(
          (dt) => dt.name
        );
        let zoneRegions = hookV2.hoveredZone
          ? optionItem.indicatorOptions.regionProfileChartOptions?.[
              "xAxis"
            ]?.categories.filter((dt) => {
              return sampleZoneInfo
                .find((_dt) => _dt.name === hookV2.hoveredZone)
                ?.regions.includes(dt);
            })
          : sortedZoneBarCategories;

        zoneRegions = [...new Set(zoneRegions ?? [])];

        const zoneBarData = [
          {
            name: "Total",
            visible: true,
            data: sortedZoneBarDataValues,
          },
        ];

        const selectedYear = hookV2?.selectedCategories?.find(
          (dt) => dt.key === optionItem.key
        )?.year;

        const SourceName: any | undefined = (hookV2.sourceNames ?? []).find(
          (s) => s.timeValue === selectedYear
        );

        // Step 1: Remove duplicate sources and sort by timeValue
        const uniqueSortedSources = Array.from(
          new Map(
            (hookV2.sourceNames ?? []).map((item) => [item.source, item])
          ).values()
        ).sort((a, b) => a.timeValue.localeCompare(b.timeValue));

        // Step 2: Extract only the source values
        const lineChartSource = uniqueSortedSources
          .map((item) => item.source)
          .join(", ");

        return (
          <Stack key={optionItem.key} spacing={10} className={"bg-white"}>
            <Stack>
              <Group position="apart" align="center">
                <Select
                  maw={600}
                  w={"100%"}
                  value={hookV2.selectedIndicator}
                  onChange={hookV2?.onChangeSelectedIndicator}
                  data={hookV2.indicators?.map((dt) => ({
                    label: dt.indicator_name,
                    value: dt.indicatorkey,
                  }))}
                  placeholder="Select indicator"
                  withAsterisk
                />

                <Group spacing={"lg"} mih={50}>
                  <SegmentedControl
                    value={stateSelection}
                    onChange={(value) =>
                      hookV2.onUpdateCategory(
                        optionItem.key,
                        value as tanzaniaStatesT
                      )
                    }
                    color="blue"
                    radius={"md"}
                    data={[
                      { label: "Tanzania", value: "all" },
                      {
                        label: "Mainland",
                        value: "mainland",
                      },
                      {
                        label: "Zanzibar",
                        value: "zanzibar",
                      },
                    ]}
                  />

                  {optionItem["hasGenderSegregation"] ? (
                    <SegmentedControl
                      value={
                        hookV2.selectedCategories.find(
                          (dt) => dt.key === optionItem.key
                        )?.genderSelection
                      }
                      onChange={(value) =>
                        hookV2.onUpdateGender(optionItem.key, value as gendersT)
                      }
                      color="blue"
                      radius={"md"}
                      data={[
                        { label: "All", value: "all" },
                        {
                          label: "Male",
                          value: "male",
                        },
                        {
                          label: "Female",
                          value: "female",
                        },
                      ]}
                    />
                  ) : (
                    <></>
                  )}
                </Group>
              </Group>
            </Stack>

            <Stack
              align={"center"}
              justify={"stretch"}
              w={"100%"}
              className={"border border-gray-200 p-1 rounded-md"}
            >
              <Text className={"text-sm text-gray-500"}>
                <span className={"capitalize"}>
                  {optionItem?.title?.text}{" "}
                  {stateSelection === "all"
                    ? "Tanzania"
                    : `Tanzania ${stateSelection}`}
                </span>
              </Text>
              {optionItem.indicatorOptions.lineChartOptions.series?.[0].data
                .length > 1 ? (
                <ImmutableChart
                  chartId="line-chart-1" // Unique chartId to identify this chart
                  option={{
                    ...optionItem.indicatorOptions.lineChartOptions,
                    exporting: {
                      enabled: false,
                    },
                  }}
                  DownloadButton={
                    <Menu shadow="md" width={200}>
                      <Menu.Target>
                        <ActionIcon className={"self-end w-fit p-2 rounded-md"}>
                          <Group spacing={"sm"}>
                            <IconMenu2 className={"text-xs"} />
                          </Group>
                        </ActionIcon>
                      </Menu.Target>

                      <Menu.Dropdown>
                        <Menu.Item
                          onClick={() =>
                            downloadLineTrendData({
                              extension: "csv",
                              name: `${optionItem.title.text} - line trend`,
                              columnHeaders: ["Year", "Total"],
                              data:
                                Array.from({
                                  length:
                                    optionItem?.indicatorOptions
                                      ?.lineChartOptions?.["xAxis"]?.categories
                                      ?.length,
                                }).map((_, ind) => ({
                                  year: optionItem?.indicatorOptions
                                    ?.lineChartOptions?.["xAxis"]?.categories[
                                    ind
                                  ],
                                  value:
                                    optionItem?.indicatorOptions
                                      ?.lineChartOptions?.["series"]?.[0]?.data[
                                      ind
                                    ],
                                })) ?? [],
                            })
                          }
                          icon={<IconFileCv size={14} />}
                        >
                          Download CSV (.csv)
                        </Menu.Item>
                        <Menu.Item
                          onClick={() =>
                            downloadLineTrendData({
                              extension: "xlsx",
                              name: `${optionItem.title.text} - line trend`,
                              columnHeaders: ["Year", "Total"],
                              data:
                                Array.from({
                                  length:
                                    optionItem?.indicatorOptions
                                      ?.lineChartOptions?.["xAxis"]?.categories
                                      ?.length,
                                }).map((_, ind) => ({
                                  year: optionItem?.indicatorOptions
                                    ?.lineChartOptions?.["xAxis"]?.categories[
                                    ind
                                  ],
                                  value:
                                    optionItem?.indicatorOptions
                                      ?.lineChartOptions?.["series"]?.[0]?.data[
                                      ind
                                    ],
                                })) ?? [],
                            })
                          }
                          icon={<IconFile size={14} />}
                        >
                          Download Excel (.xlsx)
                        </Menu.Item>

                        <Menu.Item
                          onClick={() => {
                            // Select the chart using the unique chartId
                            const chartElement =
                              document.querySelector("#line-chart-1");

                            if (!chartElement) {
                              return;
                            }

                            if (chartElement instanceof HTMLElement) {
                              html2canvas(chartElement)
                                .then((canvas) => {
                                  canvas.toBlob((blob) => {
                                    if (blob) {
                                      saveAs(
                                        blob,
                                        `${optionItem.title.text}-line-chart.png`
                                      );
                                    } else {
                                      console.error(
                                        "Failed to create blob from canvas!"
                                      );
                                    }
                                  });
                                })
                                .catch((error) => {
                                  console.error(
                                    "Error generating canvas:",
                                    error
                                  );
                                });
                            } else {
                              console.error(
                                "Chart container is not an HTMLElement."
                              );
                            }
                          }}
                          icon={<IconPhoto size={14} />}
                        >
                          Download Image (.png)
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  }
                />
              ) : (
                <>No Data</>
              )}
              <Text className={"hidden text-sm text-gray-600"}>
                {TEXTS.welcome}
              </Text>
              {optionItem.indicatorOptions.lineChartOptions.series?.[0].data
                .length > 1 && (
                <Tooltip
                  color="blue"
                  label={
                    SourceName && "link" in SourceName && SourceName?.link ? (
                      <>{SourceName.link}</>
                    ) : (
                      "No Link Available"
                    )
                  }
                >
                  <Text className="text-sm text-gray-600 italic text-center">
                    <Anchor
                      href={SourceName ? SourceName.link : ""}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-gray-600 italic"
                    >
                      Source: {lineChartSource}
                    </Anchor>
                  </Text>
                </Tooltip>
              )}
            </Stack>

            <Group position={"right"} spacing={"lg"} align={"center"}>
              <Select
                value={
                  hookV2.selectedCategories.find(
                    (dt) => dt.key === optionItem.key
                  )?.year
                }
                onChange={(value) => hookV2.onUpdateYear(optionItem.key, value)}
                placeholder="Select year"
                data={hookV2.availableYears?.map((year) => ({
                  value: year,
                  label: `${year}`,
                }))}
              />
            </Group>
            {optionItem.indicatorOptions.tableOptions.length !== 0 ? (
              <>
                <Stack className={"border border-gray-200 p-2 h-full"}>
                  <SimpleGrid cols={2} className={"h-full"}>
                    <ImmutableChart
                      option={{
                        ...optionItem.indicatorOptions.mapOptions,
                        exporting: {
                          enabled: false,
                        },
                      }}
                      isMap
                      chartId="map-chart-1"
                      DownloadButton={
                        <Menu shadow="md" width={200}>
                          <Menu.Target>
                            <ActionIcon
                              className={"self-end h-fit w-fit p-2 rounded-md"}
                            >
                              <Group spacing={"sm"}>
                                <IconMenu2 className={"text-xs"} />
                              </Group>
                            </ActionIcon>
                          </Menu.Target>

                          <Menu.Dropdown>
                            <Menu.Item
                              onClick={() =>
                                downloadRegionProfileData({
                                  extension: "csv",
                                  name: `${optionItem.title.text} - region profile`,
                                  columnHeaders: ["Region", "Zone", "Total"],
                                  data:
                                    optionItem.indicatorOptions.tableOptions?.sort(
                                      (a, b) =>
                                        a.region?.toLowerCase() >
                                        b.region?.toLowerCase()
                                          ? 1
                                          : -1
                                    ) ?? [],
                                })
                              }
                              icon={<IconFileCv size={14} />}
                            >
                              Download CSV (.csv)
                            </Menu.Item>
                            <Menu.Item
                              onClick={() =>
                                downloadRegionProfileData({
                                  extension: "xlsx",
                                  name: `${optionItem.title.text} - region profile`,
                                  columnHeaders: ["Region", "Zone", "Total"],
                                  data:
                                    optionItem.indicatorOptions.tableOptions?.sort(
                                      (a, b) =>
                                        a.region?.toLowerCase() >
                                        b.region?.toLowerCase()
                                          ? 1
                                          : -1
                                    ) ?? [],
                                })
                              }
                              icon={<IconFile size={14} />}
                            >
                              Download Excel (.xlsx)
                            </Menu.Item>
                            <Menu.Item
                              onClick={() => {
                                const chartElement =
                                  document.querySelector("#map-chart-1");

                                if (!chartElement) {
                                  console.error("Chart container not found!");
                                  return;
                                }

                                if (chartElement instanceof HTMLElement) {
                                  html2canvas(chartElement)
                                    .then((canvas) => {
                                      canvas.toBlob((blob) => {
                                        if (blob) {
                                          saveAs(
                                            blob,
                                            `${optionItem.title.text}-map-chart.png`
                                          );
                                        } else {
                                        }
                                      });
                                    })
                                    .catch((error) => {
                                      console.error(
                                        "Error generating canvas:",
                                        error
                                      );
                                    });
                                } else {
                                  console.error(
                                    "Chart container is not an HTMLElement."
                                  );
                                }
                              }}
                              icon={<IconPhoto size={14} />}
                            >
                              Download Image (.png)
                            </Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      }
                    />

                    <Stack
                      spacing={"sm"}
                      className={"h-full overflow-y-scroll"}
                    >
                      <Menu shadow="md" width={200}>
                        <Menu.Target>
                          <ActionIcon
                            className={"self-end h-fit w-fit p-2 rounded-md"}
                          >
                            <Group spacing={"sm"}>
                              <IconMenu2 className={"text-xs"} />
                            </Group>
                          </ActionIcon>
                        </Menu.Target>

                        <Menu.Dropdown>
                          <Menu.Item
                            onClick={() =>
                              downloadRegionProfileData({
                                extension: "csv",
                                name: `${optionItem.title.text} - region profile`,
                                columnHeaders: ["Region", "Zone", "Total"],
                                data:
                                  optionItem.indicatorOptions.tableOptions?.sort(
                                    (a, b) =>
                                      a.region?.toLowerCase() >
                                      b.region?.toLowerCase()
                                        ? 1
                                        : -1
                                  ) ?? [],
                              })
                            }
                            icon={<IconFileCv size={14} />}
                          >
                            Download CSV (.csv)
                          </Menu.Item>
                          <Menu.Item
                            onClick={() =>
                              downloadRegionProfileData({
                                extension: "xlsx",
                                name: `${optionItem.title.text} - region profile`,
                                columnHeaders: ["Region", "Zone", "Total"],
                                data:
                                  optionItem.indicatorOptions.tableOptions?.sort(
                                    (a, b) =>
                                      a.region?.toLowerCase() >
                                      b.region?.toLowerCase()
                                        ? 1
                                        : -1
                                  ) ?? [],
                              })
                            }
                            icon={<IconFile size={14} />}
                          >
                            Download Excel (.xlsx)
                          </Menu.Item>
                        </Menu.Dropdown>
                      </Menu>

                      <TextInput
                        placeholder={"Search ..."}
                        icon={<IconSearch />}
                        w={"100%"}
                        value={hookV2.tableSearchText}
                        onChange={(e) =>
                          hookV2.setTableSearchText(e.currentTarget.value)
                        }
                      />

                      <ScrollArea
                        type="always"
                        h={"78vh"}
                        className={"border border-gray-300 rounded-md"}
                      >
                        <Table>
                          <thead>
                            <tr>
                              <th className={"bg-blue-100"}>Region</th>
                              <th className={"bg-blue-100"}>Data value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {optionItem.indicatorOptions.tableOptions
                              ?.filter((dt) =>
                                dt.region.includes(hookV2.tableSearchText)
                              )
                              ?.sort((a, b) => b.value - a.value)
                              ?.map((dt, dtIndex) => (
                                <tr key={dtIndex}>
                                  <td>
                                    <div className={"flex items-start gap-2"}>
                                      <Text
                                        color={"dimmed"}
                                        className={"w-4 text-right"}
                                      >
                                        {dtIndex + 1}.
                                      </Text>
                                      <Text>{dt.region}</Text>
                                    </div>
                                  </td>
                                  <td>
                                    {new Intl.NumberFormat().format(dt.value)}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </ScrollArea>
                    </Stack>
                  </SimpleGrid>

                  {optionItem.indicatorOptions.tableOptions.length !== 0 && (
                    <Tooltip
                      color="blue"
                      label={
                        SourceName &&
                        "link" in SourceName &&
                        SourceName.link !== undefined ? (
                          <>{SourceName.link}</>
                        ) : (
                          "No Link Available"
                        )
                      }
                    >
                      <Text className="text-sm text-gray-600 italic text-center">
                        Source:{" "}
                        <Anchor
                          href={SourceName ? SourceName.link : ""}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-sm text-gray-600 italic"
                        >
                          {SourceName ? SourceName.source : ""}
                        </Anchor>
                      </Text>
                    </Tooltip>
                  )}
                </Stack>
              </>
            ) : (
              <></>
            )}

            {!!optionItem?.indicatorOptions?.bubbleChartOptions?.series?.[0]
              ?.data?.[0]?.value ? (
              <>
                <Stack className={"border border-gray-200 p-4"}>
                  <SimpleGrid cols={2}>
                    <ImmutableChart
                      chartId="bubble-chart-1"
                      option={{
                        ...optionItem.indicatorOptions.bubbleChartOptions,
                        exporting: {
                          enabled: false,
                        },
                      }}
                      DownloadButton={
                        <Menu shadow="md" width={200}>
                          <Menu.Target>
                            <ActionIcon
                              className={"self-end h-fit w-fit p-2 rounded-md"}
                            >
                              <Group spacing={"sm"}>
                                <IconMenu2 className={"text-xs"} />
                              </Group>
                            </ActionIcon>
                          </Menu.Target>

                          <Menu.Dropdown>
                            <Menu.Item
                              onClick={() =>
                                downloadZoneProfileData({
                                  extension: "csv",
                                  name: `${optionItem.title.text} - zone profile`,
                                  columnHeaders: ["Zone", "Total"],
                                  data:
                                    optionItem.indicatorOptions.zoneOptions?.sort(
                                      (a, b) =>
                                        a.zone?.toLowerCase() >
                                        b.zone?.toLowerCase()
                                          ? 1
                                          : -1
                                    ) ?? [],
                                })
                              }
                              icon={<IconFileCv size={14} />}
                            >
                              Download CSV (.csv)
                            </Menu.Item>
                            <Menu.Item
                              onClick={() =>
                                downloadZoneProfileData({
                                  extension: "xlsx",
                                  name: `${optionItem.title.text} - zone profile`,
                                  columnHeaders: ["Zone", "Total"],
                                  data:
                                    optionItem.indicatorOptions.zoneOptions?.sort(
                                      (a, b) =>
                                        a.zone?.toLowerCase() >
                                        b.zone?.toLowerCase()
                                          ? 1
                                          : -1
                                    ) ?? [],
                                })
                              }
                              icon={<IconFile size={14} />}
                            >
                              Download Excel (.xlsx)
                            </Menu.Item>
                            <Menu.Item
                              onClick={() => {
                                const chartElement =
                                  document.querySelector("#bubble-chart-1");

                                if (!chartElement) {
                                  console.error("Chart container not found!");
                                  return;
                                }

                                if (chartElement instanceof HTMLElement) {
                                  html2canvas(chartElement)
                                    .then((canvas) => {
                                      canvas.toBlob((blob) => {
                                        if (blob) {
                                          saveAs(
                                            blob,
                                            `${optionItem.title.text}-chart.png`
                                          );
                                        } else {
                                          console.error(
                                            "Failed to create blob from canvas!"
                                          );
                                        }
                                      });
                                    })
                                    .catch((error) => {
                                      console.error(
                                        "Error generating canvas:",
                                        error
                                      );
                                    });
                                } else {
                                  console.error(
                                    "Chart container is not an HTMLElement."
                                  );
                                }
                              }}
                              icon={<IconPhoto size={14} />}
                            >
                              Download Image (.png)
                            </Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      }
                    />

                    <Stack>
                      <Badge className={"w-fit"}>
                        {hookV2.hoveredZone ? hookV2.hoveredZone : "All Zones"}
                      </Badge>

                      <ImmutableChart
                        chartId="bar-chart-1"
                        option={{
                          ...optionItem.indicatorOptions
                            .regionProfileChartOptions,
                          xAxis: {
                            ...optionItem.indicatorOptions
                              .regionProfileChartOptions?.["xAxis"],
                            categories: zoneRegions,
                          },
                          exporting: {
                            enabled: false,
                          },
                          series: hookV2.hoveredZone
                            ? optionItem.indicatorOptions.regionProfileChartOptions?.[
                                "series"
                              ]?.map((dt) => {
                                const __data = zoneRegions?.map((region) => {
                                  const target =
                                    dt?.data?.find(
                                      (dt2) => dt2["region"] === region
                                    ) ?? null;
                                  return target;
                                });
                                const color = areaZoneColorMapV2.get(
                                  hookV2.hoveredZone as areaZonesTv2
                                );
                                return {
                                  ...dt,
                                  data: __data?.map((__dt) => ({
                                    ...__dt,
                                    color,
                                  })),
                                };
                              })
                            : zoneBarData,
                        }}
                        DownloadButton={
                          <Menu shadow="md" width={200}>
                            <Menu.Target>
                              <ActionIcon
                                className={
                                  "self-end h-fit w-fit p-2 rounded-md"
                                }
                              >
                                <Group spacing={"sm"}>
                                  <IconMenu2 className={"text-xs"} />
                                </Group>
                              </ActionIcon>
                            </Menu.Target>

                            <Menu.Dropdown>
                              <Menu.Item
                                onClick={() =>
                                  downloadRegionProfileData({
                                    extension: "csv",
                                    name: `${optionItem.title.text} - region profile`,
                                    columnHeaders: ["Region", "Zone", "Total"],
                                    data:
                                      optionItem.indicatorOptions.tableOptions?.sort(
                                        (a, b) =>
                                          a.region?.toLowerCase() >
                                          b.region?.toLowerCase()
                                            ? 1
                                            : -1
                                      ) ?? [],
                                    withZone: true,
                                  })
                                }
                                icon={<IconFileCv size={14} />}
                              >
                                Download CSV (.csv)
                              </Menu.Item>
                              <Menu.Item
                                onClick={() =>
                                  downloadRegionProfileData({
                                    extension: "xlsx",
                                    name: `${optionItem.title.text} - region profile`,
                                    columnHeaders: ["Region", "Zone", "Total"],
                                    data:
                                      optionItem.indicatorOptions.tableOptions?.sort(
                                        (a, b) =>
                                          a.region?.toLowerCase() >
                                          b.region?.toLowerCase()
                                            ? 1
                                            : -1
                                      ) ?? [],
                                    withZone: true,
                                  })
                                }
                                icon={<IconFile size={14} />}
                              >
                                Download Excel (.xlsx)
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  const chartElement =
                                    document.querySelector("#bar-chart-1");

                                  if (!chartElement) {
                                    console.error("Chart container not found!");
                                    return;
                                  }

                                  if (chartElement instanceof HTMLElement) {
                                    html2canvas(chartElement)
                                      .then((canvas) => {
                                        canvas.toBlob((blob) => {
                                          if (blob) {
                                            saveAs(
                                              blob,
                                              `${optionItem.title.text}-bar-chart.png`
                                            );
                                          } else {
                                            console.error(
                                              "Failed to create blob from canvas!"
                                            );
                                          }
                                        });
                                      })
                                      .catch((error) => {
                                        console.error(
                                          "Error generating canvas:",
                                          error
                                        );
                                      });
                                  } else {
                                    console.error(
                                      "Chart container is not an HTMLElement."
                                    );
                                  }
                                }}
                                icon={<IconPhoto size={14} />}
                              >
                                Download Image (.png)
                              </Menu.Item>
                            </Menu.Dropdown>
                          </Menu>
                        }
                      />
                    </Stack>
                  </SimpleGrid>
                  <Text className={"hidden text-sm text-gray-600"}>
                    {TEXTS.welcome}
                  </Text>{" "}
                  {optionItem.indicatorOptions.bubbleChartOptions.series
                    .length > 0 && (
                    <Tooltip
                      color="blue"
                      label={
                        SourceName && SourceName.link !== null ? (
                          <>{SourceName.link}</>
                        ) : (
                          "No Link Available"
                        )
                      }
                    >
                      <Text className="text-sm text-gray-600 italic text-center">
                        Source:{" "}
                        <Anchor
                          href={SourceName ? SourceName.link : ""}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-sm text-gray-600 italic"
                        >
                          {SourceName ? SourceName.source : ""}
                        </Anchor>
                      </Text>
                    </Tooltip>
                  )}
                </Stack>
              </>
            ) : (
              <></>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
}

function ImmutableChart(props: {
  option: any;
  isMap?: boolean;
  withoutBorder?: boolean;
  DownloadButton?: React.ReactNode;
  chartId?: string; // Ensure chartId is accepted as a prop
}) {
  const [chartOptions, setChartOptions] = useState<
    Highcharts.Options | undefined
  >(undefined);

  useEffect(() => {
    setChartOptions(props.option);
  }, [props.option]);

  const Chart = (
    <HighchartsReact
      constructorType={props.isMap ? "mapChart" : undefined}
      highcharts={props.isMap ? formap : Highcharts}
      options={chartOptions}
      {...HighchartsReact["Props"]}
      allowChartUpdate
      immutable
      oneToOne={true}
    />
  );

  if (!chartOptions || !Object.keys(chartOptions).length) return <div></div>;

  return (
    <div className="flex flex-col h-[100%] gap-4 w-[90%] h-fit border border-gray-300 rounded-md p-3">
      <div>
        <div className="flex justify-end">{props.DownloadButton}</div>
      </div>
      <div id={props.chartId ? props.chartId : "chart-id"}>
        <div>{Chart}</div>
      </div>
    </div>
  );
}

import { createUrl } from "~/utils/functions/create-url";
import { TopicEntity } from "./topic-entity";
import { TopicRepositoryContract } from "./topic-repository-contract";

class TopicRepository implements TopicRepositoryContract {
  async getAll(): Promise<TopicEntity[]> {
    const url = createUrl("dhs/topic/");

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(
          `Failed to fetch data: ${response.status} ${response.statusText}`
        );
      }
      const data = await response.json();

      return data.map(
        ({ indicator_name, indicatorkey, subsector_name, subsectorkey, uom }) =>
          new TopicEntity(
            indicatorkey,
            `${indicator_name}, ${uom}`,
            uom,
            subsectorkey,
            subsector_name
          )
      );
    } catch (error) {
      throw error;
    }
  }
}

export const topicRepository = new TopicRepository();

import {
  Box,
  Breadcrumbs,
  createStyles,
  Flex,
  Stack,
  Text,
  Anchor,
} from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import homeImage from "~/assets/images/dhs-bg.jpeg";
import { TopicsGridUi } from "~/domains/topic/ui/components";
import { useGetTopics } from "~/domains/topic/ui/hooks";
import { useTranslator } from "~/i18n/translator-hook";
import { routePaths } from "~/router/router";

const style = createStyles((theme) => ({
  fontsize: {
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color: theme.colors.blue[4],
  },
}));

export const HomePage: React.FC = () => {
  const { classes } = style();
  const translator = useTranslator();
  const breadcrumbsItems = [
    { title: "DHS", path: routePaths.root },
    { title: translator("home") },
  ].map((item, index) => {
    if (!item.path) {
      return <Text size={"sm"}>{item.title}</Text>;
    }
    return (
      <NavLink to={item.path} key={index} className={classes.fontsize}>
        {item.title}
      </NavLink>
    );
  });
  const { loading: topicsLoading, topics } = useGetTopics();

  const [search] = useState("");
  const filteredTopics = React.useMemo(() => {
    const normalizedSearch = search.toLowerCase().trim().split(" ").join();

    const sortedTopics = topics.sort((a, b) => {
      const aSubsector = a.subsector_name.toLowerCase();
      const bSubsector = b.subsector_name.toLowerCase();

      const aStartsWithNutrition = aSubsector.startsWith("nutrition");
      const bStartsWithNutrition = bSubsector.startsWith("nutrition");
      const aStartsWithViolence = aSubsector.startsWith("violence");
      const bStartsWithViolence = bSubsector.startsWith("violence");

      // Prioritize "nutrition"
      if (aStartsWithNutrition && !bStartsWithNutrition) return -1;
      if (!aStartsWithNutrition && bStartsWithNutrition) return 1;

      // Deprioritize "violence"
      if (aStartsWithViolence && !bStartsWithViolence) return 1;
      if (!aStartsWithViolence && bStartsWithViolence) return -1;

      return 0; // Maintain original order for other cases
    });

    return normalizedSearch.length
      ? sortedTopics.filter((topic) =>
          topic.subsector_name.toLowerCase().includes(normalizedSearch)
        )
      : sortedTopics;
  }, [search, topics]);

  const rgb = "242,243,245";

  return (
    <Stack spacing={"sm"}>
      <div className="flex justify-between items-center">
        <Breadcrumbs separator={<IconChevronRight size={18} />}>
          {breadcrumbsItems}
        </Breadcrumbs>
        <Anchor
          size={"small"}
          mr={20}
          fw={500}
          href="https://microdata.nbs.go.tz/index.php/catalog?page=1&sk=dhs&sort_by=rank&sort_order=desc&ps=15"
          target="_blank"
        >
          RAW DATA
        </Anchor>
      </div>
      <Stack spacing={0}>
        <Flex
          p={"xl"}
          h={180}
          w={"100%"}
          gap={"xl"}
          justify={"center"}
          align={"center"}
          sx={() => ({
            backgroundColor: `rgba(${rgb},1)`,
            // background: "rgb(2,0,36)",
            // background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(21,21,64,0) 23%, rgba(18,18,80,0.2007177871148459) 36%, rgba(9,9,121,1) 68%, rgba(28,29,29,1) 100%)',
          })}
        >
          <Box h="100%" w="33%" sx={{ position: "relative" }}>
            <img
              src={homeImage}
              alt={""}
              style={{
                objectFit: "cover",
                height: "100%",
                width: "70%",
              }}
            />

            <Box
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                background: `linear-gradient(90deg, rgba(${rgb},1) 0%, rgba(${rgb},0) 33%, rgba(${rgb},0) 66%, rgba(${rgb},1) 100%), linear-gradient(180deg, rgba(${rgb},1) 0%, rgba(${rgb},0) 33%, rgba(${rgb},0) 66%, rgba(${rgb},1) 100%)`,
              }}
            />
          </Box>
          <Text
            w={"100%"}
            className={"text-xl"}
            // align="left"
            sx={() => ({ fontSize: "medium" })}
          >
            <strong className={"underline underline-offset-2"}>
              The Demographic and Health Surveys (DHS) Dashboard
            </strong>{" "}
            provides current and reliable information on health issues that
            intends to assist <strong>decision makers</strong> and{" "}
            <strong>programme managers</strong> in evaluating and designing
            programmes and strategies for improving the health of Tanzania’s
            population and <strong>monitor</strong> national and international
            programs such as <strong>FYDPs</strong> and <strong>SDGs</strong>.
          </Text>
        </Flex>

        <Box w={"100%"} mb={"md"}>
          {filteredTopics.length ? (
            <>
              <TopicsGridUi
                topics={filteredTopics}
                showTitle
                filtered={!!search.length}
              />
            </>
          ) : topicsLoading ? (
            <></>
          ) : (
            <Text align={"center"} color={"dimmed"} size={"sm"}>
              No topics found
            </Text>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};
